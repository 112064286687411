import React from "react"
import Layout from "../components/layout"
import { Container, Row, Col, Badge, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import Helmet from 'react-helmet'
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdSense from 'react-adsense';

class TeamTemplate extends React.Component {

    render() {

        let { team, state } = this.props.pageContext;

        return (
            <Layout>
                <Helmet
                    title={team.name}
                    meta={[
                        { name: 'description', content: team.name + " Team Page" },
                    ]}
                />
                <div className="wrapper">

                    <Container style={{ backgroundColor: "white", padding: "0px", borderTop: "4px solid #343a40", marginBottom: "25px" }}>
                        <Row noGutters>
                            <Col md={4} style={{ backgroundColor: "#f1f1f1", padding: "40px" }}>
                                <img src={team.icon} style={{ height: "auto", width: "100%" }} alt={team.name} />
                                <div style={{marginTop: "20px"}}>                                    
                                    {team.contactEmail && <div><FontAwesomeIcon width="0" icon={faEnvelopeSquare} /> <a href={"mailto:" + team.contactEmail}>{team.contactEmail}</a></div>}
                                    {team.facebook && <div><FontAwesomeIcon width="0" icon={faFacebookSquare} /> <a href={"http://www.facebook.com/" + team.facebook} rel="noopener noreferrer nofollow">Facebook</a></div>}
                                    {team.twitter && <div><FontAwesomeIcon width="0" icon={faTwitterSquare} /> <a href={"http://www.twitter.com/" + team.twitter} rel="noopener noreferrer nofollow">Twitter</a></div>}
                                    {team.instagram && <div><FontAwesomeIcon width="0" icon={faInstagram} /> <a href={"http://www.instagram.com/" + team.instagram} rel="noopener noreferrer nofollow">Instagram</a></div>}
                                    
                                </div>

                            </Col>
                            <Col md={8} style={{ padding: "20px 50px 100px 50px" }}>

                                <div>
                                    <Breadcrumb>
                                        <BreadcrumbItem><a href={"/softball-map"}>Softball Map</a></BreadcrumbItem>
                                        <BreadcrumbItem><a href={"/softball-map/" + state.slug}>{state.name}</a></BreadcrumbItem>
                                        <BreadcrumbItem active>{team.name}</BreadcrumbItem>
                                    </Breadcrumb>
                                </div>

                                <h1>{team.name}</h1>
                                <div>{team.address}</div>
                                <div>{team.city}, {team.state}</div>

                                <div style={{marginTop: "25px"}}><Badge style={{ fontSize: "18px" }}>{team.age}</Badge></div>

                                <h4 style={{ color: "#6610f2", marginTop: "25px" }}>Head Coach</h4>
                                <div>{team.coach}</div>

                                <h4 style={{ color: "#6610f2", marginTop: "25px" }}>Team Description</h4>
                                <div>{team.description}</div>
                            </Col>
                        </Row>

                    </Container>

                    <Container style={{marginBottom: "25px", padding: "0px"}}>

                        <Row>
                            <Col>
                                <AdSense.Google
                                    client='ca-pub-7485372285264586'
                                    slot='7699694065'
                                    style={{ display: 'block', margin: "0 auto", width: "100%", height: "auto" }}
                                    format='auto'
                                    responsive='true'
                                />

                            </Col>
                        </Row>

                    </Container>
                </div>
            </Layout>
        )
    }

}

export default TeamTemplate
